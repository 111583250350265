.fixtures-container {
  font-family: Arial, sans-serif;
  padding: 20px;
}

h2 {
  text-align: left;
  font-size: 2rem;
  color: #ffffff;
}

.match-cards-row {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  gap: 20px; /* Space between cards */
  overflow-x: auto; /* Enable horizontal scrolling */
  padding-bottom: 10px; /* Add padding to the bottom */
}

.match-card {
  background-color: white;
  padding: 15px 20px 10px 20px; /* Less padding, especially at the bottom */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: auto; /* Adjust width to ensure cards fit properly */
  cursor: grab;
  transition: transform 0.2s ease;
  margin-right: 20px; /* Adds padding between each card */
}

.match-card:active {
  transform: scale(1.05); /* Slightly scale the card when dragged */
}

.match-header {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #555;
}

.match-header .match-date {
  font-weight: bold;
}

.match-header .match-status {
  background-color: #f76917;
  color: white;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 0.8rem;
}

.match-details {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.team {
  text-align: center; /* Center-align content */
  display: flex;
  flex-direction: column; /* Stack the logo and name vertically */
  align-items: center; /* Center them horizontally */
}

.team-logo {
  width: 150px; /* Adjust the size of the logo */
  height: auto;
  margin-bottom: -20px; /* Add a small space between the logo and team name */
}

.team h3 {
  font-size: 1rem; /* Adjust font size */
  color: #333;
  margin: 0; /* Remove extra margins */
}

.team p {
  color: #555;
  font-size: 1rem;
}

.result {
  font-size: 0.9rem;
  margin-top: 10px;
  font-weight: 600;
  color: #333;
}


/* Mobile View: Make adjustments for smaller screens */
@media (max-width: 768px) {
  .match-card {
    height: 160px; /* Set a fixed height for all cards */
    width: 315px;;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }

  .match-cards-row {
    display: flex;
    
    flex-direction: row; /* Ensure horizontal layout */
    overflow-x: auto; /* Enable horizontal scrolling */
  }


  .match-header,
  .match-details {
    flex: 0 0 auto; /* Prevent these sections from stretching */
  }

  .team {
    flex-direction: column; /* Ensure vertical stacking */
    align-items: center; /* Center align */
  }

  .team-logo {
    width: 100px; /* Smaller logo for mobile */
    margin-bottom: 0; /* Remove margin to reduce space */
  }

  .team h3 {
    font-size: 0.8rem; /* Smaller font for mobile */
    margin-top: 1px; /* Add a little margin above the team name */
  }

  .result {
    font-size: 0.8rem; /* Adjust result text size */
  }
}
