/* Style for the parent section containing the link */
.section-container {
    display: flex;                 /* Enables Flexbox */
    justify-content: center;       /* Centers the child horizontally */
    align-items: center;           /* Aligns the child vertically, if needed */
    width: 90%;                    /* Section width set to 90% */
    margin: 0 auto;                /* Centers the section in the page */
    text-align: center;            /* Ensures that text inside any child element of this container is centered */
}
  
/* Style for the fixture link */
.fixture-link {
    display: flex;                 /* Makes the link behave like a flex container */
    justify-content: center;       /* Horizontally centers the content inside */
    align-items: center;           /* Vertically centers the content */
    text-decoration: none;
    font-size: 18px;               /* Keeps the font size constant */
    color: #db164d;
    font-weight: bold;
    text-transform: uppercase;     /* Makes the text uppercase */
    letter-spacing: 2px;           /* Adds spacing between letters */
    padding: 10px 20px;            /* Adds padding around the text */
    background-color: rgb(255, 255, 255); /* Background color to highlight the link */
    border-radius: 5px;            /* Adds rounded corners */
    transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Smooth animation for hover */
    margin: 20px auto;             /* Adds margin at the top and bottom, horizontally centers the element */
    height: auto;                  /* Ensures no height issues */
    box-sizing: border-box;        /* Ensures padding doesn't affect overall element size */
    width: 97%;                    /* Link takes up 100% width of the parent container */
    /* Elevated effect and shadow */
    box-shadow: 0 4px 8px rgb(126, 121, 121); /* Adds subtle shadow to elevate the element */
    transform: translateY(-3px);  /* Moves the box slightly upward */
}
    
/* Hover effect for the link */
.fixture-link:hover {
    background: linear-gradient(to right, #9e1c41, #410c57, #28023a); /* Changes the background color on hover */
    color: white;                  /* Changes the text color on hover */
    box-shadow: 0 6px 12px rgba(255, 0, 0, 0.3); /* Darker and more pronounced shadow on hover */
    transform: translateY(-6px);   /* Moves the box further up on hover */
}
