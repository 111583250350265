/* Reset for Body and HTML */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scroll if an image overflows */
}

/* Banner Container */
.banner-container {
  width: 100%;
  height: auto; /* Default height for desktop */
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Default: Hide mobile banner and show desktop banner */
.desktop-banner {
  display: block;
  width: 100%;
  margin: 0; /* Ensure no margin is applied */
  padding: 0; /* Ensure no padding is applied */
}

.mobile-banner {
  display: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Banner Image Styling */
.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area without distortion */
  margin: 0; /* Remove any margin from the image */
  padding: 0; /* Remove any padding from the image */
  display: block; /* Avoid inline spacing for images */
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  /* Hide Desktop Banner on Mobile */
  .desktop-banner {
    display: none; /* Hide the desktop banner on mobile */
  }

  /* Show Mobile Banner on Mobile */
  .mobile-banner {
    display: block; /* Show the mobile banner */
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }

  /* Adjust Banner Container Height on Mobile */
  .banner-container {
    height: auto; /* Adjust height for mobile view */
  }

  /* Ensure mobile banner images maintain good aspect ratio */
  .mobile-banner .banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
  }
}

/* Optional: Hide Mobile Banner on Desktop */
@media (min-width: 1024px) {
  .mobile-banner {
    display: none; /* Hide the mobile banner on desktop */
  }
}
