/* Prevent horizontal overflow */
body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevents horizontal overflow */
}

/* Marquee Container */
.marquee-container {
    background-color: #ffffff;
    width: 100%;
    padding: 10px 0;
    overflow: hidden;
    position: relative;
}

.marquee-text {
    display: flex;

    justify-content: center;
    align-items: center;
    animation: marquee 30s linear infinite; /* Creates the sliding effect */
}

.marquee-text-content {
    font-size: 18px;
    font-weight: bold;
    color: #3b115b;
    white-space: nowrap;
}

/* Main Header */
.header {
    background-color: #021e5a;
    width: 100%;
    font-family: Arial, sans-serif;
    background: linear-gradient(to right,#9e1c41, #410c57,#28023a );
    display: flex;
    flex-direction: column; /* Stack header content */
}

/* Top Section */
.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    box-sizing: border-box; /* Ensure padding doesn't affect width */
    width: 100%;
    overflow: hidden; /* Prevent overflow */
}

/* Logo Section */
.logo-section {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-shrink: 0; /* Prevent logo from shrinking */
}

.logo-img {
    height: 100px; /* Default logo height */
}

/* Hamburger Menu */
.hamburger-menu {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    height: 20px;
    cursor: pointer;
    position: absolute; /* Ensure it doesn't move with the content */
    top: auto; /* Adjust based on your header height */
    right: 20px;
    z-index: 10; /* Ensure it stays on top of the content */
    background: #2e0441;
    padding: 10px; /* Add some padding for better visuals */
    border-radius: 5px; /* Optional: add rounded corners */
    transition: top 0.3s ease, background-color 0.3s ease; /* Smooth animation for position and background color */
}

.hamburger-menu.open {
    background-color: #ffd700; /* Slightly darker yellow when open (optional) */
}

.hamburger-menu .line {
    width: 25px;
    height: 3px;
    background-color: #fff;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.hamburger-menu .line.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu .line.open:nth-child(2) {
    opacity: 0;
}

.hamburger-menu .line.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

/* Header Content */
.header-content {
    display: flex;
    gap: 20px;
    justify-content: flex-end; /* Push navigation and actions to the right */
    align-items: center;
    width: 100%;
}

/* Navigation Links */
.nav-links {
    display: flex;
    gap: 35px;
    justify-content: flex-start; /* Align links horizontally */
}

.nav-link {
    color: #db164d;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    padding: 10px 10px;
    border-radius: 5px;
    background-color: #ffffff;
    transition: background-color 0.3s ease, color 0.3s ease;
    
}

.nav-link:hover {
    background-color: #e4ca61;
    color: #001a33;
}

/* Actions Section */
.actions {
    display: flex;
    align-items: center;
    gap: 15px;
}

.languages {
    display: flex;
    gap: 5px;
}

.language-link {
    color: #ffffff;
    text-decoration: none;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #ffffff;
    border-radius: 4px;
    background-color: #19052c; /* Same background as nav-links */
    transition: background-color 0.3s ease, color 0.3s ease;
}


.language-link:hover {
    background-color: #db164d; /* Same hover background as nav-link */
    color: #001a33; /* Same hover text color as nav-link */
}

.language-link.selected {
    background-color: #ab68e9; /* Keep this as default selected state */
    color: #ffffff; /* Text color for selected state */
    border-color: #ffffff; /* Keep border color same as the hover color */
}


.language-link.selected:hover {
    background-color: #e4ca61; /* Same hover background as nav-link */
    color: #001a33; /* Same hover text color as nav-link */
}



.shop-link,
.membership-link,
.sign-in-link {
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
}

.shop-link:hover,
.membership-link:hover,
.sign-in-link:hover {
    color: #db164d; /* Change to your desired hover color */
    text-decoration: underline; /* Adds an underline on hover */
    transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth transition effect */
}







.membership-link {
    color: #ffffff;
}

.search-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.search-input {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    background-color: #001a33;
    color: #ffffff;
    font-size: 14px;
}


.search-button {
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    color: #001a33;
    font-weight: bold;
    cursor: pointer;
    padding: 5px 10px;
}


.search-button:hover {
    background: linear-gradient(to right,#9e1c41, #28023a );
    border: none;
    border-radius: 4px;
    color: #001a33;
    font-weight: bold;
    cursor: pointer;
    padding: 5px 10px;
}



/* Marquee Animation */
@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

/* Responsive Navigation */
@media (max-width: 768px) {
    .hamburger-menu {
        display: flex;
    }

    .header-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        width: 100%;
        overflow: hidden;
        max-height: 0; /* Initially collapsed */
        opacity: 0; /* Hide content initially */
        transform: scaleY(0); /* Shrink vertically */
        transform-origin: top; /* Set origin for the scale animation */
        transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease; /* Smooth transition */
    }


   .header-content.open {
        max-height: 500px; /* Expand height */
        opacity: 1; /* Make content visible */
        transform: scaleY(1); /* Expand vertically */
    }

    .nav-links {
        display: flex;
        gap: 10px;
        flex-direction: column;
        width: 100%;
        text-align: center;
    }

    .actions {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        text-align: center;
    }
    .languages {
        margin-bottom: 10px;
    }
}

/* Desktop Layout */
@media (min-width: 769px) {
    .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px 25px;
    }

    .header-content {
        display: flex;
        gap: 20px;
        justify-content: flex-start; /* Align everything to the left side */
        align-items: center;
        flex-grow: 1;
        padding-left: 130px;
    }

    .nav-links {
        justify-content: flex-start; /* Align nav links to the left */
        margin-right: auto; /* Push the links to the left by giving margin-right auto */

    }
    .actions {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .logo-img {
        height: 120px;
    }


    .nav-link {
        color: #db164d;
        text-decoration: none;
        font-weight: bolder;
        font-size: 16px;
        padding: 10px 10px;
        border-radius: 5px;
        
        background-color: #ffffff;
        transition: background-color 0.3s ease, color 0.3s ease;
        
    }

    


     .nav-link:hover {
        background: linear-gradient(to right,#9e1c41, #28023a );
        
        color: #ffffff;

    }

    .nav-link:nth-child(1), /* Home */
    .nav-link:nth-child(2), /* Latest Update */
    .nav-link:nth-child(3) { /* Squad */
        border: 2px solid #ffffff; /* Add white border around these buttons */
    }

    



}




.popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
    font-size: 16px;
  }
  
  .popup p {
    margin: 0;
  }