/* Default card styles */
.card {
  width: 70%;  /* Adjust width for larger screens */
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  margin: 5px;  /* Reduced margin for desktop view */
}

.card img {
  width: 400px;  /* Ensure image fits within the card */
  margin-bottom: 8px;
  border-radius: 10px;
}

.card .player-name {
  font-weight: bold;
  margin-bottom: 4px;
}

.card .player-role {
  font-style: italic;
}

/* Responsive styles for mobile view */
@media (max-width: 600px) {
  .card {
      width: 100%;  /* Adjust width for smaller screens */
      margin: 1px;  /* Adjust the margin for smaller devices */
  }

  .card img {
    border-radius: 10px;
      width: 361px;  /* Reduce image width for smaller screens */
  }

  .card .player-name {
      font-size: 14px;  /* Optional: Adjust font size for mobile */
  }

  .card .player-role {
      font-size: 12px;  /* Optional: Adjust font size for mobile */
  }
}
