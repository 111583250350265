/* Default styles for larger screens */
.squad-section {
    padding-left: 20px;
    padding-top: 1px;
    padding-bottom: 4px;
    text-align: left;
    font-family: "Arial Black", Gadget, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -2px;
    color: #002663;
    background-color: aliceblue;
  }
  
  .squad-text {
    margin: 0; /* Optional, to remove default margin on the heading */
  }
  
  .black-line {
    width: 99%; /* Full width */
    height: 3px; /* Line thickness */
    background-color: black; /* Black color */
    margin-bottom: 10px; /* Space between the line and text */
  }
  
  /* Mobile-specific styles */
  @media (max-width: 768px) {
    .squad-section {
      padding-left: 10px;
      padding-right: 10px; /* Added right padding for mobile */
      text-align: center; /* Center the text */
      font-size: 14px; /* Smaller font size */
      font-weight: normal; /* Lighter font weight */
      letter-spacing: 0; /* Normal letter spacing */
    }
  
    .black-line {
      width: 80%; /* Narrow the line on mobile */
      margin-left: auto;
      margin-right: auto; /* Center the black line */
    }
  }
  