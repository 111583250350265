/* General Container */
.about-container {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #ffffff;
    margin: 0 auto;
    padding: 20px;
    max-width: 800px;
    text-align: justify;
  }
  
  /* Header */
  .about-header {
    text-align: center;
    margin-bottom: 30px;
    padding: 10px 0;
    /* background: linear-gradient(to right, #9e1c41, #410c57, #28023a); */
    color: white;
    border-radius: 5px;
  }
  
  .about-header h1 {
    font-size: 2.5rem;
    margin: 0;
  }
  
  /* Section Styling */
  .about-section {
    margin-bottom: 30px;
  }
  
  .about-section h2 {
    font-size: 1.8rem;
    color: #ffffff;
    border-bottom: 2px solid #004ba0;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  .about-section p,
  .about-section ul {
    margin: 0;
  }
  
  .about-section ul {
    list-style: disc;
    padding-left: 20px;
  }
  
  /* Footer */
  .about-footer {
    text-align: center;
    margin-top: 30px;
    padding: 10px 0;
    
    color: white;
    border-radius: 5px;
  }
  
  .about-footer p {
    margin: 0;
  }
  