/* Footer Container */
.footer {
  background: linear-gradient(to right,#9e1c41, #410c57,#28023a );
  color: #fff;
  padding: 30px 0;
  text-align: center; /* Ensure all text in the footer is centered */
}

.footer-logo img {
  width: 150px;
  height: 150px;
  margin: 0 auto; /* Ensure the logo is centered horizontally */
}

/* Increase logo height for desktop view */
@media (min-width: 1024px) { /* Apply this for screen widths 1024px and above */
  .footer-logo img {
    height: 200px; /* Increase height of the logo */
  }
}

/* Footer Bottom */
.footer-bottom {
  text-align: center; /* Center align text */
  font-size: 14px;
  padding-top: 20px;
  border-top: 1px solid #444;
}

/* Flexbox to control column alignments */
.row {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  justify-content: center; /* Center columns horizontally */
  align-items: center; /* Center items vertically */
  width: 100%; /* Ensure the row spans the full width */
}

/* Column 1: Left-aligned (Logo) */
.col-lg-3:first-of-type {
  display: flex;
  justify-content: center; /* Center align horizontally */
  align-items: center; /* Center align vertically */
  text-align: center; /* Align text to the center */
  flex: 0 0 25%; /* Set width to 25% of the row */
}

/* Column 2: Center-aligned (Quick Links and Text) */
.col-lg-6 {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  flex-direction: column; /* Stack content vertically */
  text-align: center; /* Center-align text */
  flex: 0 0 45%; /* Set width to 50% of the row */
}

/* Column 3: Right-aligned (Powered By and Banner Image) */
.col-lg-3:last-of-type {
  display: flex;
  justify-content: center; /* Keep it centered horizontally */
  align-items: center; /* Center align vertically */
  text-align: center; /* Align text to the center */
  flex: 0 0 25%; /* Set width to 25% of the row */
  position: relative; /* Allows for margin adjustments */
}

.banner-image2 {
  display: flex;
  justify-content: center; /* Center the banner image horizontally */
  align-items: center; /* Center the banner image vertically */
  width: 80%;
  margin-left: 0; /* Remove the previous left margin */
  margin-top: 10px; /* Add some space above the banner if needed */
}



.footer-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.list-unstyled {
  list-style-type: none;
  padding-left: 0;
}

.list-unstyled li {
  margin-bottom: 13px;
}

.list-unstyled a {
  color: #fff;
  text-decoration: none;
}

.social-media a {
  margin-right: 15px;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
}

.social-media a:hover {
  color: #1e90ff;
}

/* Responsive styling */
@media (max-width: 768px) {
  .row {
      flex-direction: column; /* Stack columns vertically on small screens */
  }

  .col-lg-3, .col-lg-6 {
      text-align: center; /* Ensure text is centered */
  }


  .list-unstyled {
    list-style-type: none;
    padding-left: 0;
  }
  
  .list-unstyled li {
    margin-bottom: 18px;
  }
  
  .list-unstyled a {
    color: #fff;
    text-decoration: none;
  }
  




}

/* Optional: Hover effect for social icons */
.social-media a:hover {
  color: #1e90ff;
}

.banner-img2 {
  width: 90%;
  height: auto;
  margin-bottom: 15px;
}

.footer-logo img {
  width: 200px;
  height: 200px;
}

footer .footer-bottom {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1rem;
  font-size: 0.8rem;
  text-align: center; /* Center align copyright */
}

.powered-by {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center vertically */
  text-align: center; /* Ensure text alignment is centered */
  width: 100%; /* Occupies the full column width */
}


.powered-text {
  font-size: 40px; /* Increase font size */
  font-weight: bold; /* Make text bold */
  margin-bottom: 5px; /* Reduced margin below the text to decrease space */
}
@media (max-width: 768px) {
  .powered-text {
    font-size: 34px; /* Smaller font size for mobile */
    margin-bottom: 3px; /* Further reduce space on mobile */
}


.banner-img2 {
  width: 100%;
  height: auto;
  margin-top: 5px; /* Maintain reduced space on mobile */
}
}
