/* SectionFirst.css */
.slider-container {
    width: 100vw; /* Full width of the viewport */
    position: relative;
    padding-top: 5px; /* Only top margin */
    overflow: hidden;
    background-color: #ffffff; /* White background */
  }
  
  .slider-image {
    width: 100%;
    height: auto;
    display: block;
  }
  