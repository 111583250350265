.card1 {
    width: 90%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    cursor: pointer;
    text-align: left; /* Align text left in the card */
}

.card1 img {
    width: 100%;
    margin-bottom: 8px;
    margin-top: 20px;
    border-radius: 10px;
}

.card1 .date {
    font-weight: italic;
    color: white;
    margin-bottom: 4px;
}

.card1 .title {
    font-weight: bold;
    color: white;
    padding-bottom: 8px;
    padding-top: 5px;
}


.card1 .brief {
    font-weight: 300;
    color: white;
}






@media (max-width: 600px) {
    .card1 {
        width: 100%; /* Make card full width on mobile */
        height: auto; /* Adjust height for smaller screens */
    }
    .card1 img {
        width: 100%; /* Ensure image takes full width */
    }
}



