/* Floating icons container */
.floating-icons {
    position: fixed;
    z-index: 1000;
  }

  .icon-link:hover{
    background: linear-gradient(to right,#9e1c41, #410c57,#28023a );
  }
  
  
  /* For mobile view */
  .floating-icons-mobile {
    right: 10px; /* Adjust right position for mobile screens */
    bottom: 50px; /* Position icons slightly higher from the bottom for mobile */
  }
  


  .floating-icons-desktop {
    right: 20px; /* Adjust right position for desktop screens */
    top: 50%; /* Vertically center the icons */
    transform: translateY(-50%); /* Ensure exact vertical centering */
    bottom: auto; /* Remove bottom positioning */
  }
  
  /* Icon list styling */
  .icon-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden; /* Ensures content stays hidden when collapsed */
    max-height: 0; /* Initially collapsed */
    opacity: 0; /* Fully transparent initially */
    transition: max-height 0.5s ease, opacity 0.5s ease; /* Smooth transition for height and opacity */
}
  
.icon-list.show {
  max-height: 500px; /* Large enough to accommodate the icons */
  opacity: 1; /* Fully visible */
}
  .icon-item {
    margin: 10px 0;
  }
  
  .icon-link {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #000;
    padding: 8px; /* Smaller padding for mobile */
    border-radius: 50%;
    text-decoration: none;
    font-size: 12px; /* Smaller font size for mobile */
    transition: background-color 0.3s ease;
  }
  
  .icon-link img {
    width: 25px;
    height: 25px;
  }
  
  .toggle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #000;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .toggle-button img {
    width: 25px;
    height: 25px;
  }
  
  /* For desktop styling */
  @media (min-width: 768px) {
    .floating-icons {
      position: fixed; /* Ensure the container stays fixed */
    }
  
    .floating-icons-mobile {
      display: none; /* Hide toggle button in desktop view */
    }
  
    .icon-list {
      display: block; /* Show icons by default for desktop */
      max-height: none; /* No height restriction for desktop */
      opacity: 1; /* Fully visible */
  }
    .icon-link {
      padding: 10px; /* Larger padding for desktop */
      font-size: 14px; /* Larger font size for desktop */
    }
  
    .icon-link img {
      width: 30px;
      height: 30px;
    }
  }
  